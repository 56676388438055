$taas-primary-base-color: #0068B4; // Utimaco Blue
$taas-secondary-base-color: #003760;
$taas-sidebar-base-color: #001c30;
$taas-hightlight-color: #fbba00; // Utimaco Gold
$taas-default-text-color: #3C3C3C; // Utimaco Grey Text

$header-height: 64px;
$side-bar-left-width: 50px;
$main-navigation-width: 250px;
$taas-default-font: "Roboto";

h1 {
    color: $taas-primary-base-color;
    font-weight: 300;
    font-size: 26px;
}