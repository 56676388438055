// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@use "global_style_variables.scss" as taas;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

//generated using http://mcg.mbitson.com/#!?utimacoportal=%230068b4

$md-utimacoportal-primary: (
  50 : #e0edf6,
  100 : #b3d2e9,
  200 : #80b4da,
  300 : #4d95cb,
  400 : #267fbf,
  500 : #0068b4,
  600 : #0060ad,
  700 : #0055a4,
  800 : #004b9c,
  900 : #003a8c,
  A100 : #b8d0ff,
  A200 : #85aeff,
  A400 : #528bff,
  A700 : #397aff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$md-utimacoportal-secondary: (
  50 : #fff7e0,
  100 : #feeab3,
  200 : #fddd80,
  300 : #fccf4d,
  400 : #fcc426,
  500 : #fbba00,
  600 : #fab300,
  700 : #faab00,
  800 : #f9a300,
  900 : #f89400,
  A100 : #ffffff,
  A200 : #fff6eb,
  A400 : #ffdfb8,
  A700 : #ffd39f,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/ 
$taas-portal-primary: mat.m2-define-palette($md-utimacoportal-primary, 500);
$taas-portal-accent: mat.m2-define-palette($md-utimacoportal-secondary, 500);

// The warn palette is optional (defaults to red).
$utimaco-portal-mock-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$taas-portal-theme: mat.m2-define-light-theme((color: (primary: $taas-portal-primary,
        accent: $taas-portal-accent,
        warn: $utimaco-portal-mock-warn,
      ),
      typography: mat.m2-define-typography-config(),
      density: 0));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($taas-portal-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  --mdc-protected-button-label-text-weight: 300; //overwriting md button label here, to match style guide
  --mdc-outlined-button-label-text-weight: 300;
  --mdc-text-button-label-text-weight: 300;
}

body {
  margin: 0;
  font-family: taas.$taas-default-font, sans-serif;
  color: taas.$taas-default-text-color;
}

h1 {
  color: taas.$taas-primary-base-color;
  font-size: 22px;
  line-height: 28px;
  font-weight: 300;
}

h3,
h2 {
  color: taas.$taas-secondary-base-color;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
}

p,
div {
  color: taas.$taas-default-text-color;
  font-size: 13px;
  line-height: 19px;
  font-family: taas.$taas-default-font, sans-serif;
}

.mat-mdc-card,
.md-card,
.mat-mdc-card-content {
  border-radius: 0px !important;
}

button, .mdc-button {
  border-radius: 3px !important;
}

